<template>
  <div
    class="flex h-screen bg-gray-50 dark:bg-gray-900"
    :class="{ 'overflow-hidden': isSideMenuOpen }"
  >
    <!-- Desktop sidebar -->
    <aside
      :class="`z-20 hidden w-64 overflow-y-auto bg-white dark:bg-gray-800 md:block flex-shrink-0 ${
        hideSidebar ? 'hide-side' : ''
      }`"
      style="
        -webkit-box-shadow: 8px 0px 15px -6px rgba(0, 0, 0, 0.7);
        -moz-box-shadow: 8px 0px 15px -6px rgba(0, 0, 0, 0.7);
        box-shadow: 8px 0px 15px -6px rgba(0, 0, 0, 0.7);
      "
    >
      <div class="py-4 text-gray-500 dark:text-gray-400">
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <img src="img/vacancy.jpg" alt="" style="width: 70%" />
        </div>
        <!-- <a
          class="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200"
          href="#"
        >
          Vacancy
        </a> -->
        <ul class="mt-6">
          <!-- <li class="relative px-6 py-3"> -->

          <!-- Active items have the snippet below -->
          <!-- <span
                class="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span> -->

          <!-- Add this classes to an active anchor (a tag) -->
          <!-- text-gray-800 dark:text-gray-100 -->
          <!-- <a
              class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
            >
              <svg
                class="w-5 h-5"
                aria-hidden="true"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                ></path>
              </svg>
              <span class="ml-4">Dashboard</span>
            </a>
          </li> -->
        </ul>
        <ul>
          <!-- <li class="relative px-6 py-3">
            <a
              class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              href="../tables.html"
            >
              <svg
                class="w-5 h-5"
                aria-hidden="true"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 10h16M4 14h16M4 18h16"></path>
              </svg>
              <span class="ml-4">Tables</span>
            </a>
          </li> -->
          <li
            class="relative px-6 py-3"
            v-for="item in menuItems.filter((m) => m.show)"
          >
            <button
              class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              @click="setMenu(item.exec, item.redirect)"
              aria-haspopup="true"
            >
              <span class="inline-flex items-center">
                <b-icon :pack="item.iconPack" :icon="item.icon" size="is-small">
                </b-icon>
                <span class="ml-4">{{ i18n[item.i18n][iso] }}</span>
              </span>
              <svg
                v-if="item.subItems.length > 0"
                class="w-4 h-4"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
            <template v-if="item.subItems.length > 0 && selected === item.exec">
              <ul
                x-transition:enter="transition-all ease-in-out duration-300"
                x-transition:enter-start="opacity-25 max-h-0"
                x-transition:enter-end="opacity-100 max-h-xl"
                x-transition:leave="transition-all ease-in-out duration-300"
                x-transition:leave-start="opacity-100 max-h-xl"
                x-transition:leave-end="opacity-0 max-h-0"
                class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900"
                aria-label="submenu"
              >
                <li
                  class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                  v-for="sub in item.subItems"
                >
                  <a
                    @click="goPath(sub.redirect)"
                    class="w-full"
                    style="cursor: pointer"
                    >{{ i18n[sub.i18n][iso] }}</a
                  >
                </li>
              </ul>
            </template>
          </li>
        </ul>
      </div>
      <!-- version -->
      <div style="display: flex; align-items: center; justify-content: center">
        <b-tag
          style="position: fixed; bottom: 12%"
          type="is-primary is-light"
          >{{ version }}</b-tag
        >
        <b-button
          style="position: fixed; bottom: 5%"
          type="is-link is-light"
          icon-left="arrow-left"
          icon-pack="fas"
          @click="hideSidebar = true"
        >
          {{ hide[iso] }}
        </b-button>
      </div>
    </aside>
    <!-- Mobile sidebar -->
    <!-- Backdrop -->
    <div
      v-show="isSideMenuOpen"
      x-transition:enter="transition ease-in-out duration-150"
      x-transition:enter-start="opacity-0"
      x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in-out duration-150"
      x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0"
      class="fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
    ></div>
    <aside
      class="fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 md:hidden"
      v-show="isSideMenuOpen"
      x-transition:enter="transition ease-in-out duration-150"
      x-transition:enter-start="opacity-0 transform -translate-x-20"
      x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in-out duration-150"
      x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0 transform -translate-x-20"
    >
      <div class="py-4 text-gray-500 dark:text-gray-400">
        <a
          class="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200"
          href="#"
        >
          Vacancy
        </a>
        <ul class="mt-6">
          <!-- <li class="relative px-6 py-3"> -->
          <!-- Active items have the snippet below -->
          <!-- <span
                class="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span> -->

          <!-- Add this classes to an active anchor (a tag) -->
          <!-- text-gray-800 dark:text-gray-100 -->
          <!-- <a
              class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              href="../index.html"
            >
              <svg
                class="w-5 h-5"
                aria-hidden="true"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                ></path>
              </svg>
              <span class="ml-4">Dashboard</span>
            </a>
          </li> -->
        </ul>
        <ul>
          <!-- <li class="relative px-6 py-3">
            <a
              class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              href="../forms.html"
            >
              <svg
                class="w-5 h-5"
                aria-hidden="true"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                ></path>
              </svg>
              <span class="ml-4">Forms</span>
            </a>
          </li> -->
          <li class="relative px-6 py-3">
            <button
              class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              @click="setMenuMobile('rci')"
              aria-haspopup="true"
            >
              <span class="inline-flex items-center">
                <svg
                  class="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
                  ></path>
                </svg>
                <span class="ml-4">RCI</span>
              </span>
              <svg
                class="w-4 h-4"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
            <template v-if="selectedMobile === 'rci'">
              <ul
                x-transition:enter="transition-all ease-in-out duration-300"
                x-transition:enter-start="opacity-25 max-h-0"
                x-transition:enter-end="opacity-100 max-h-xl"
                x-transition:leave="transition-all ease-in-out duration-300"
                x-transition:leave-start="opacity-100 max-h-xl"
                x-transition:leave-end="opacity-0 max-h-0"
                class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900"
                aria-label="submenu"
              >
                <li
                  class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                >
                  <a @click="goPath('/week')" class="w-full">Semanas</a>
                </li>
                <li
                  class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                >
                  <a @click="goPath('/reserve')" class="w-full">Reservas</a>
                </li>
              </ul>
            </template>
          </li>
        </ul>
      </div>
    </aside>
    <div class="flex flex-col flex-1">
      <header
        class="z-10 py-4 bg-white shadow-md dark:bg-gray-800"
        style="
          -webkit-box-shadow: 0px 8px 15px -6px rgba(0, 0, 0, 0.7);
          -moz-box-shadow: 0px 8px 15px -6px rgba(0, 0, 0, 0.7);
          box-shadow: 0px 8px 15px -6px rgba(0, 0, 0, 0.7);
        "
      >
        <div
          class="container flex items-center justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300"
        >
          <!-- Mobile hamburger -->
          <b-button
            style="position: fixed; left: 3%"
            type="is-link is-light"
            icon-left="arrow-right"
            icon-pack="fas"
            @click="hideSidebar = false"
          >
            {{ open[iso] }}
          </b-button>
          <!-- <button
            class="p-1 -ml-1 mr-5 rounded-md focus:outline-none focus:shadow-outline-purple"
            @click="toggleSideMenu"
            aria-label="Menu"
          >
            <svg
              class="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button> -->
          <!-- Search input -->
          <div
            class="flex justify-center flex-1 lg:mr-32"
            style="visibility: hidden"
          >
            <div
              class="relative w-full max-w-xl mr-6 focus-within:text-purple-500"
            >
              <div class="absolute inset-y-0 flex items-center pl-2">
                <svg
                  class="w-4 h-4"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                class="w-full pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-100 border-0 rounded-md dark:placeholder-gray-500 dark:focus:shadow-outline-gray dark:focus:placeholder-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:placeholder-gray-500 focus:bg-white focus:border-purple-300 focus:outline-none focus:shadow-outline-purple form-input"
                type="text"
                placeholder="Search for projects"
                aria-label="Search"
              />
            </div>
          </div>
          <ul class="flex items-center flex-shrink-0 space-x-6">
            <!-- Theme toggler -->
            <!-- <li class="flex">
              <button
                class="rounded-md focus:outline-none focus:shadow-outline-purple"
                @click="toggleTheme"
                aria-label="Toggle color mode"
              >
                <template x-if="!dark">
                  <svg
                    class="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
                    ></path>
                  </svg>
                </template>
                <template x-if="dark">
                  <svg
                    class="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </template>
              </button>
            </li> -->
            <!-- Profile menu -->
            <p>
              <strong>{{ getNameUser() }}</strong>
            </p>
            <li class="relative">
              <button
                class="align-middle rounded-full focus:shadow-outline-purple focus:outline-none"
                @click="setProfile"
                aria-label="Account"
                aria-haspopup="true"
              >
                <img
                  class="object-cover w-8 h-8 rounded-full"
                  src="img/profile.png"
                  alt=""
                  aria-hidden="true"
                />
              </button>
              <template v-if="profileView">
                <ul
                  x-transition:leave="transition ease-in duration-150"
                  x-transition:leave-start="opacity-100"
                  x-transition:leave-end="opacity-0"
                  class="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700"
                  aria-label="submenu"
                >
                  <!-- <li class="flex">
                    <a
                      class="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                      href="#"
                    >
                      <svg
                        class="w-4 h-4 mr-3"
                        aria-hidden="true"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                        ></path>
                      </svg>
                      <span>Profile</span>
                    </a>
                  </li> -->
                  <!-- <li class="flex">
                    <a
                      class="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                      href="#"
                    >
                      <svg
                        class="w-4 h-4 mr-3"
                        aria-hidden="true"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                        ></path>
                        <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                      </svg>
                      <span>Settings</span>
                    </a>
                  </li> -->
                  <li class="flex">
                    <a
                      class="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                      @click="exit"
                    >
                      <svg
                        class="w-4 h-4 mr-3"
                        aria-hidden="true"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                        ></path>
                      </svg>
                      <span>{{ logout[iso] }}</span>
                    </a>
                  </li>
                </ul>
              </template>
            </li>
            <li class="relative">
              <button
                class="align-middle rounded-full focus:shadow-outline-purple focus:outline-none"
                @click="changeIso"
                aria-label="Account"
                aria-haspopup="true"
              >
                <img
                  class="object-cover w-8 h-8 rounded-full"
                  :src="`img/${iso || 'es'}.png`"
                  alt=""
                  aria-hidden="true"
                />
              </button>
            </li>
          </ul>
        </div>
      </header>
      <main class="h-full pb-16 overflow-y-auto">
        <!-- Remove everything INSIDE this div to a really blank page -->
        <div class="container px-6 mx-auto grid" style="max-width: 1744px">
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { changeIso, logout, weeks, reserves, hide, open } from "../utils/i18n";
import i18n from "../utils/i18n";
import { checkMenuItems } from "../utils/permissions";

export default {
  name: "Sidebar",

  data() {
    return {
      selected: null,
      profileView: false,
      isSideMenuOpen: false,
      isPagesMenuOpen: false,
      selectedMobile: null,
      iso: null,
      i18n,
      logout,
      weeks,
      reserves,
      hide,
      hideSidebar: false,
      open,
      version: "v01042025",
      menuItems: [
        {
          redirect: "/new-member",
          i18n: "catcher",
          exec: "capturador",
          icon: "file-alt",
          iconPack: "fas",
          show: false,
          subItems: [],
          module: "capturer",
        },
        {
          redirect: null,
          i18n: "activations",
          exec: "activations",
          icon: "toggle-on",
          iconPack: "fas",
          show: false,
          subItems: [
            {
              redirect: "/activations",
              i18n: "assignmentActivation",
              module: "list-members-activations",
            },
          ],
        },
        {
          redirect: null,
          i18n: "members",
          exec: "catcher",
          icon: "user-tie",
          iconPack: "fas",
          show: true,
          subItems: [
            {
              redirect: "/catcher-members",
              i18n: "search",
              module: "list-members",
            },
          ],
        },
        {
          redirect: null,
          i18n: "rci",
          exec: "rci",
          icon: "plane",
          iconPack: "fas",
          show: true,

          subItems: [
            {
              redirect: "/week",
              i18n: "weeks",
              module: "list-weeks",
            },
            {
              redirect: "/reserve",
              i18n: "reserves",
              module: "list-bookings",
            },
          ],
        },
        // {
        //   redirect: null,
        //   i18n: "settings",
        //   exec: "config",
        //   icon: "wrench",
        //   iconPack: "fas",
        //   show: false,
        //   subItems: [
        //     {
        //       redirect: "/setting-payment",
        //       i18n: "payments",
        //     },
        //   ],
        // },
        {
          redirect: null,
          i18n: "renewals",
          exec: "renewals",
          icon: "sync",
          iconPack: "fas",
          show: false,
          subItems: [
            {
              redirect: "/online-renewals",
              i18n: "online",
              module: "renewals-online",
            },
            // {
            //   redirect: "/renewals-follow-up",
            //   i18n: "followUpRenewals",
            //   module: "renewals-follow-up",
            // },
            // {
            //   redirect: "/customer-portfolio-old",
            //   i18n: "customerPortfolioOld",
            //   module: "renewals-customer-portfolio",
            // },
            {
              redirect: "/customer-portfolio",
              i18n: "customerPortfolio",
              module: "renewals-customer-portfolio",
            },
            {
              redirect: "/statistics",
              i18n: "statistics",
              module: "renewals-statistics",
            },
            // {
            //   redirect: "/renewals-follow-up",
            //   i18n: "followUpRenewals",
            // },
          ],
        },
        // {
        //   redirect: "/renewals-global",
        //   i18n: "renewals",
        //   exec: "renewalsGlobal",
        //   icon: "file-alt",
        //   iconPack: "fas",
        //   show: false,
        //   subItems: [],
        //   module: "renewals-global",
        // },
        // {
        //   redirect: "/payments-links",
        //   i18n: "paymentsLinks",
        //   exec: "paymentsLinks",
        //   icon: "dollar-sign",
        //   iconPack: "fas",
        //   show: false,
        //   subItems: [],
        //   module: "list-paymentslinks",
        // },
        /* {
          redirect: "/bookings",
          i18n: "bookings",
          exec: "bookings",
          icon: "plane-departure",
          iconPack: "fas",
          show: false,
          subItems: [],
          module: "list-bookings",
        }, */
        {
          redirect: null,
          i18n: "system",
          exec: "system",
          icon: "user-shield",
          iconPack: "fas",
          show: false,
          subItems: [
            {
              redirect: "/system-users",
              i18n: "users",
              module: "list-system-users",
            },
            // {
            //   redirect: "/system-roles",
            //   i18n: "roles",
            // },
          ],
        },
        // {
        //   redirect: null,
        //   i18n: "catalogs",
        //   exec: "catalogs",
        //   icon: "book",
        //   iconPack: "fas",
        //   show: false,
        //   subItems: [
        //     {
        //       redirect: "/discount-codes",
        //       i18n: "discountCodes",
        //       module: "list-codigos-descuento",
        //     },
        //   ],
        // },
        {
          redirect: "links",
          i18n: "vrsys",
          exec: "links",
          icon: "external-link-alt",
          iconPack: "fas",
          show: false,
          subItems: [],
          module: "links",
        },
      ],
    };
  },

  mounted() {},

  created() {
    this.insertIcon();
    this.insertName();

    this.iso = localStorage.getItem("iso");

    this.menuItems = checkMenuItems(this.menuItems);

    // this.i18n = changeIso[localStorage.getItem("iso")];
    // console.log(this.i18n);
  },

  methods: {
    insertIcon() {
      let link = null;
      link = document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href =
        "https://booking.vacancyrewards.com/css/clientes/vacancyrewards/images/favicon.png";
      document.getElementsByTagName("head")[0].appendChild(link);
    },

    insertName() {
      let text = document.createTextNode("Vacancy Rewards");
      let title = document.createElement("title");
      title.appendChild(text);
      document.getElementsByTagName("head")[0].appendChild(title);
    },
    setMenu(menu, redirect = null) {
      if (redirect === "links") {
        window.open(
          `https://vrsys.vacancyrewards.com/login?token=${localStorage.getItem(
            "token"
          )}&email=${localStorage.getItem("userEmail")}`,
          "_blank"
        );
      } else {
        if (this.selected === menu) this.selected = null;
        else this.selected = menu;

        if (redirect) this.goPath(redirect);
      }
    },
    setMenuMobile(menu) {
      if (this.selectedMobile === menu) this.selectedMobile = null;
      else this.selectedMobile = menu;
    },
    setProfile() {
      this.profileView = !this.profileView;
    },
    togglePagesMenu() {
      this.isPagesMenuOpen = !this.isPagesMenuOpen;
    },
    toggleSideMenu() {
      this.isSideMenuOpen = !this.isSideMenuOpen;
    },
    goPath(path) {
      this.$router.push(path).catch(() => {});
    },
    getNameUser() {
      return localStorage.getItem("user");
    },
    exit() {
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("userId");
      localStorage.removeItem("permissions");
      localStorage.removeItem("clubes");
      localStorage.removeItem("club");
      localStorage.removeItem("token");
      this.goPath("/");
    },
    changeIso() {
      this.$buefy.dialog.confirm({
        message: changeIso[this.iso],
        type: "is-info",
        onConfirm: () => {
          if (localStorage.getItem("iso") === "es") {
            localStorage.setItem("iso", "en");
            this.iso = "en";
          } else {
            localStorage.setItem("iso", "es");
            this.iso = "es";
          }
          // this.$forceUpdate();
          // this.$buefy.toast.open("User confirmed")
          this.$bus.emit("change-iso");
        },
      });
    },
  },
};
</script>

<style scoped>
.hide-side {
  display: none;
}
</style>
