<template>
  <div class="container">
    <div class="mt-3">
      <p class="title">{{ i18n.email[iso] }}</p>
      <div class="columns is-multiline">
        <div class="column is-4">
          <div class="card-content tarjeta" @click="sendEmailStatusMembership">
            <div class="mr-3">
              <i class="fa fa-handshake fa-2x"></i>
            </div>
            <div v-if="loadingCard === 'membership'">
              <h3
                class="subtitle has-text-weight-bold"
                style="margin-bottom: 6px"
              >
                {{ i18n.loading[iso] }}
              </h3>
              <p></p>
            </div>
            <div v-else>
              <h3
                class="subtitle has-text-weight-bold"
                style="margin-bottom: 6px"
              >
                {{ i18n.sendWelcomeEmail[iso] }}
              </h3>
              <p>{{ i18n.descSendWelcmomeEmail[iso] }}</p>
            </div>
          </div>
        </div>

        <div class="column is-4">
          <div class="card-content tarjeta" @click="recoveryPassword">
            <div class="mr-3">
              <i class="fa fa-key fa-2x"></i>
            </div>
            <div v-if="loadingCard === 'password'">
              <h3
                class="subtitle has-text-weight-bold"
                style="margin-bottom: 6px"
              >
                {{ i18n.loading[iso] }}
              </h3>
              <p></p>
            </div>
            <div v-else>
              <h3
                class="subtitle has-text-weight-bold"
                style="margin-bottom: 6px"
              >
                {{ i18n.sendPasswordRecoveryEmail[iso] }}
              </h3>
              <p>{{ i18n.descPasswordRecoveryEmail[iso] }}</p>
            </div>
          </div>
        </div>

        <div class="column is-4">
          <div class="card-content tarjeta" @click="recoveryPasswordDefault">
            <div class="mr-3">
              <i class="fa fa-sync fa-2x"></i>
            </div>
            <div v-if="loadingCard === 'password-default'">
              <h3
                class="subtitle has-text-weight-bold"
                style="margin-bottom: 6px"
              >
                {{ i18n.loading[iso] }}
              </h3>
              <p></p>
            </div>
            <div v-else>
              <h3
                class="subtitle has-text-weight-bold"
                style="margin-bottom: 6px"
              >
                {{ i18n.resetPassword[iso] }}
              </h3>
              <p>{{ i18n.resetPassword__desc[iso] }}</p>
            </div>
          </div>
        </div>

        <div class="column is-4" v-if="ifSyncBitrix && isAdmin">
          <div class="card-content tarjeta" @click="addContactBitrix">
            <div class="mr-3">
              <i class="fa fa-user-plus fa-2x"></i>
            </div>
            <div v-if="loadingCard === 'bitrix'">
              <h3
                class="subtitle has-text-weight-bold"
                style="margin-bottom: 6px"
              >
                {{ i18n.loading[iso] }}
              </h3>
              <p></p>
            </div>
            <div v-else>
              <h3
                class="subtitle has-text-weight-bold"
                style="margin-bottom: 6px"
              >
                {{ i18n.syncContactInBitrix[iso] }}
              </h3>
              <p>{{ i18n.syncContactInBitrix_msg1[iso] }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showModal" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <h5>{{ i18n.recoveryPassword__msg1[iso] }}</h5>
          </div>
        </div>
        <footer class="card-footer">
          <a @click="recoveryPassword__exec('C')" class="card-footer-item"
            ><b>{{ i18n.coOwner[iso] }}</b></a
          >
          <a @click="recoveryPassword__exec('T')" class="card-footer-item"
            ><b>{{ i18n.owner[iso] }}</b></a
          >
        </footer>
      </div>
    </b-modal>
    <b-modal v-model="showModalDefault" :width="540" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <h5>{{ i18n.resetPassword__msg3[iso] }}</h5>
          </div>
        </div>
        <footer class="card-footer">
          <a @click="recoveryPasswordDefault__exec('C')" class="card-footer-item"
            ><b>{{ i18n.coOwner[iso] }}</b></a
          >
          <a @click="recoveryPasswordDefault__exec('T')" class="card-footer-item"
            ><b>{{ i18n.owner[iso] }}</b></a
          >
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import i18n, { contract } from "../../utils/i18n";
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "ActionsMember",
  props: {
    contract: {
      type: Object,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
    coOwner: {
      type: Object,
    },
    email: {
      type: String,
      default: "",
    },
    ifSyncBitrix: {
      type: Boolean,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      i18n,
      iso: null,
      isLoading: false,
      loadingCard: null,
      showModal: false,
      showModalDefault: false,
      formProps: {
        email: "evan@you.com",
        password: "testing",
      },
    };
  },
  methods: {
    async sendEmailStatusMembership() {
      this.loadingCard = "membership";
      try {
        await axios.put(
          `${API_URL}/catcher/edit-status-membership/contract/${
            this.contract.Id
          }?forceTrue=y&updatedBy=${localStorage.getItem("user")}`
        );
        // this.$buefy.toast.open({
        //   message: "OK",
        //   type: "is-success",
        // });
        this.$buefy.dialog.confirm({
          message: this.i18n.sendWelcomeEmail__msg1[this.iso],
          type: "is-info",
        });

        await this.$bus.emit("refresh-comments");
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al enviar email",
          type: "is-danger",
        });
      } finally {
        this.loadingCard = null;
      }
    },
    recoveryPassword() {
      if (this.member?.coOwner && this.member?.coOwner?.Email) {
        this.showModal = true;
        console.log("this.member.coOwner", this.member.coOwner);
      } else {
        this.recoveryPassword__exec("T");
      }
    },
    async recoveryPassword__exec(type) {
      // console.log("recoveryPassword__exec", type);
      this.showModal = false;
      this.loadingCard = "password";
      try {
        await axios.get(
          `${API_URL}/recovery-password/members/send?email=${
            type === "C" ? this.member.coOwner.Email : this.member.Email
          }`
        );
        this.$buefy.dialog.confirm({
          message: `${this.i18n.sendPasswordRecoveryEmail__msg1[this.iso]} (${
            type === "C" ? this.member.coOwner.Email : this.member.Email
          })`,
          type: "is-info",
        });
      } catch (err) {
        this.$buefy.toast.open({
          message: "Error al enviar email",
          type: "is-danger",
        });
      } finally {
        this.loadingCard = null;
      }
    },
    async addContactBitrix() {
      this.loadingCard = "bitrix";
      try {
        await axios.post(`${API_URL}/bitrix/sync/contact`, {
          member: this.member,
          contract: this.contract,
        });
        if (this.member?.coOwner) {
          await axios.post(`${API_URL}/bitrix/sync/contact`, {
            member: this.member?.coOwner,
            contract: this.contract,
          });
        }

        this.$buefy.toast.open({
          message: this.i18n.syncContactInBitrix_msg2[this.iso],
          type: "is-success",
        });

        this.loadingCard = null;
        window.location.reload();
        // this.$emit("refresh-profile");
        // this.$forceUpdate();
      } catch (err) {
        this.$buefy.toast.open({
          message: "Error al crear contacto en Bitrix24",
          type: "is-danger",
        });
        this.loadingCard = null;
      }
    },
    recoveryPasswordDefault() {
      if (this.member?.coOwner && this.member?.coOwner?.Email) {
        this.showModalDefault = true;
        // console.log("this.member.coOwner", this.member.coOwner);
      } else {
        this.recoveryPasswordDefault__exec("T");
      }
    },
    async recoveryPasswordDefault__exec(type) {
      // console.log("recoveryPassword__exec", type);
      this.showModalDefault = false;
      this.loadingCard = "password-default";
      try {
        await axios.get(
          `${API_URL}/catcher/members/change-password?email=${
            type === "C" ? this.member.coOwner.Email : this.member.Email
          }`
        );
        this.$buefy.dialog.confirm({
          message: `${this.i18n.resetPassword__msg1[this.iso]} (${
            type === "C" ? this.member.coOwner.Email : this.member.Email
          })`,
          type: "is-info",
        });
      } catch (err) {
        this.$buefy.toast.open({
          message: this.i18n.resetPassword__msg2[this.iso],
          type: "is-danger",
        });
      } finally {
        this.loadingCard = null;
      }
    },
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },
  async mounted() {
    console.log(this.contract);
  },
};
</script>

<style scoped>
.tarjeta {
  background-color: rgb(234, 236, 240);
  display: flex;
  height: 165px;
  width: 430px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tarjeta:hover {
  background-color: #48c78e;
}
</style>
