<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <p class="title">{{ i18n.customerPortfolio[iso] }}</p>
        <!-- <p class="subtitle">Hero subtitle</p> -->
      </div>
    </section>
    <section style="margin-top: 30px">
      <b-tabs v-model="tabFilters" position="is-centered" @input="changeTab">
        <!-- Resultados -->
        <b-tab-item>
          <template #header>
            <b-icon icon="users" pack="fas" type="is-success"></b-icon>
            <b>{{ i18n.portfolio[iso] }}</b>
          </template>

          <!-- Filtros -->
          <div class="columns is-multiline">
            <div class="column is-3">
              <b-field label-position="on-border" label="LoginID">
                <b-input
                  v-model="advancedFilters.LoginID"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.contract[iso]">
                <b-input
                  v-model="advancedFilters.nroContract"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3" v-if="permissions !== 'admin'">
              <b-field label-position="on-border" :label="i18n.email[iso]">
                <b-input
                  v-model="advancedFilters.Email"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.renewalDate[iso]"
              >
                <!-- <b-datepicker
                  v-model="advancedFilters.FechaRenovacion"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  icon="calendar"
                  icon-pack="fas"
                  :icon-right="
                    advancedFilters.FechaRenovacion.length > 0 ? 'times' : ''
                  "
                  icon-right-clickable
                  @icon-right-click="advancedFilters.FechaRenovacion = []"
                >
                </b-datepicker> -->
                <b-datepicker
                  v-model="FechaRenovacionMes"
                  type="month"
                  icon="calendar"
                  icon-pack="fas"
                  trap-focus
                  icon-right-clickable
                  @icon-right-click="inputFilterRenewalRemove"
                  :icon-right="FechaRenovacionMes ? 'times' : ''"
                  :locale="iso"
                  @input="inputFilterRenewal"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-3" v-if="permissions === 'admin'">
              <b-field label-position="on-border" :label="'Club'">
                <b-dropdown
                  v-model="advancedFilters.ClubId"
                  multiple
                  aria-role="list"
                  expanded
                  scrollable
                  @change="changeSelectedAllClubsInFilters"
                >
                  <template #trigger>
                    <b-button
                      type="is-dark"
                      icon-right="chevron-down"
                      icon-pack="fas"
                      expanded
                      inverted
                    >
                      {{ i18n.clubsSelected[iso] }}:
                      {{ advancedFilters.ClubId.length }}
                    </b-button>
                  </template>

                  <b-dropdown-item aria-role="listitem" custom>
                    <b-field
                      label-position="on-border"
                      :label="i18n.search[iso]"
                    >
                      <b-input
                        type="text"
                        expanded
                        @input="findSelectedAllClubsInFilters"
                      >
                      </b-input>
                    </b-field>
                  </b-dropdown-item>

                  <b-dropdown-item aria-role="listitem" separator />

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="selectedAllClubsInFilters"
                    v-if="!checkSelectedAllClubsInFilters"
                  >
                    <span style="color: #0d68ce"
                      ><b>{{ i18n.selectAll[iso] }}</b></span
                    >
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="removeSelectedAllClubsInFilters"
                    v-if="checkSelectedAllClubsInFilters"
                  >
                    <span style="color: #cc0f35"
                      ><b>{{ i18n.unselectAll[iso] }}</b></span
                    >
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-for="club in clubesRgx"
                    :value="club.Id"
                    :key="club._id"
                    aria-role="listitem"
                  >
                    <span>{{ club.Nombre }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-field>
            </div>
            <div class="column is-3" v-if="permissions === 'admin'">
              <b-field
                label-position="on-border"
                :label="i18n.assignedAgent[iso]"
              >
                <b-select expanded v-model="advancedFilters.staffRenewal">
                  <option :value="null"></option>
                  <option
                    v-for="user in staff"
                    :value="user.Usuario"
                    :key="user._id"
                  >
                    {{ user.Nombre }} {{ user.Apellido }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.assignedAt[iso]">
                <!-- <b-datepicker
                  v-model="advancedFilters.FechaAsignacionRenovacion"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate__FechaAsignacionRenovacion"
                  :max-date="maxDate__FechaAsignacionRenovacion"
                  :locale="iso"
                  range
                  icon="calendar"
                  icon-pack="fas"
                  :icon-right="
                    advancedFilters.FechaAsignacionRenovacion.length > 0
                      ? 'times'
                      : ''
                  "
                  icon-right-clickable
                  @icon-right-click="
                    advancedFilters.FechaAsignacionRenovacion = []
                  "
                >
                </b-datepicker> -->
                <b-datepicker
                  v-model="FechaAsignacionRenovacionMes"
                  :min-date="minDate__FechaAsignacionRenovacion"
                  :max-date="maxDate__FechaAsignacionRenovacion"
                  type="month"
                  icon="calendar"
                  icon-pack="fas"
                  trap-focus
                  icon-right-clickable
                  @icon-right-click="inputFilterAssignedRenewalRemove"
                  :icon-right="FechaAsignacionRenovacionMes ? 'times' : ''"
                  :locale="iso"
                  @input="inputFilterAssignedRenewal"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field
                label-position="on-border"
                :label="i18n.paymentStatus[iso]"
              >
                <b-select
                  expanded
                  v-model="advancedFilters.paymentStatusRenewal"
                >
                  <option :value="null"></option>
                  <option :value="true">
                    {{ i18n.charged[iso] }}
                  </option>
                  <option :value="false">
                    {{ i18n.noCharged[iso] }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-2" v-if="permissions === 'admin'">
              <b-field
                label-position="on-border"
                :label="i18n.untableContracts[iso]"
              >
                <b-select
                  expanded
                  v-model="advancedFilters.noAssignmentRenewal"
                >
                  <option :value="null">
                    {{ i18n.all[iso] }}
                  </option>
                  <option :value="1">
                    {{ i18n.onlyAssigned[iso] }}
                  </option>
                  <option :value="2">
                    {{ i18n.noAssignment[iso] }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-2" v-if="permissions === 'admin'">
              <b-field label-position="on-border" :label="i18n.language[iso]">
                <b-select expanded v-model="advancedFilters.Idioma">
                  <option :value="null"></option>
                  <option value="SP">
                    {{ i18n.spanish[iso] }}
                  </option>
                  <option value="EN">
                    {{ i18n.english[iso] }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <!-- <div class="column is-8"></div> -->
            <div class="column is-2">
              <b-button
                icon-left="search"
                icon-pack="fas"
                type="is-primary"
                @click="advancedSearch(false)"
                expanded
                :loading="loadingMembers"
                >{{ i18n.search[iso] }}</b-button
              >
            </div>
            <div class="column is-2">
              <b-button
                icon-left="redo"
                icon-pack="fas"
                type="is-info is-light"
                @click="clearAdvancedSearch"
                expanded
                >{{ i18n.resetSearch[iso] }}</b-button
              >
            </div>
            <div class="column is-2" v-if="members.length > 0">
              <b-button
                icon-left="file"
                icon-pack="fas"
                type="is-info"
                @click="exportDataToCsv(false)"
                expanded
                :loading="exportDataToCsv__loading"
                >{{ i18n.exportToCsv[iso] }}</b-button
              >
            </div>
          </div>

          <!-- Acciones -->
          <b-tabs position="is-centered" v-model="tabActions">
            <b-tab-item :visible="permissions === 'admin'">
              <template #header>
                <b-icon icon="hands-helping" pack="fas" type="is-info"></b-icon>
                <b>{{ i18n.assignments[iso] }}</b>
              </template>
              <div class="columns is-multiline">
                <div class="column is-3">
                  <b-field
                    label-position="on-border"
                    :label="i18n.selectAgent[iso]"
                  >
                    <b-dropdown
                      v-model="selectedStaff"
                      multiple
                      aria-role="list"
                      expanded
                      scrollable
                    >
                      <template #trigger>
                        <b-button
                          type="is-dark"
                          icon-right="chevron-down"
                          icon-pack="fas"
                          expanded
                          inverted
                        >
                          {{ i18n.selectedStaff[iso] }}:
                          {{ selectedStaff.length }}
                        </b-button>
                      </template>

                      <b-dropdown-item
                        v-for="user in staff"
                        :value="user.Usuario"
                        :key="user._id"
                        aria-role="listitem"
                      >
                        <span>{{ user.Nombre }} {{ user.Apellido }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-field>
                </div>
                <div class="column is-2">
                  <b-button
                    icon-left="hands-helping"
                    icon-pack="fas"
                    type="is-primary"
                    expanded
                    @click="assignStaffToContracts"
                    :loading="assignStaffToContractsFetch__loading"
                    >{{ i18n.assign[iso] }}</b-button
                  >
                </div>
                <div class="column is-5"></div>
                <div class="column is-2">
                  <b-button
                    icon-left="times"
                    icon-pack="fas"
                    type="is-danger"
                    expanded
                    @click="removeAssignments"
                    :loading="removeAssignmentsFetch__loading"
                    >{{ i18n.removeAssignments[iso] }}</b-button
                  >
                </div>
              </div>
            </b-tab-item>
            <b-tab-item
              :visible="allPermissions.includes('renewals-customer-portfolio')"
            >
              <template #header>
                <b-icon
                  icon="dollar-sign"
                  pack="fas"
                  type="is-success"
                ></b-icon>
                <b>{{ i18n.paymentLink[iso] }}</b>
              </template>
              <div class="columns is-multiline">
                <div class="column is-12">
                  <b-message type="is-warning" size="is-small">
                    <b
                      >{{ i18n.alert[iso] }}:
                      {{ i18n.createPaymentsLinks__msg3[iso] }}</b
                    >
                  </b-message>
                </div>

                <div class="column is-2">
                  <b-field :label="i18n.period[iso]">
                    <b-select
                      v-model="paymentLink.Years"
                      expanded
                      @input="checkPrice"
                      :disabled="disabledYears"
                    >
                      <option
                        v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 99]"
                        :key="item"
                        :value="item"
                      >
                        <span v-if="item === 1"
                          >{{ item }} {{ i18n.year[iso] }}</span
                        >
                        <span v-else-if="item === 99">No more fees</span>
                        <span v-else>{{ item }} {{ i18n.years[iso] }}</span>
                      </option>
                    </b-select></b-field
                  >
                </div>
                <div class="column is-2">
                  <b-field
                    :label="i18n.notifySendNotifications[iso]"
                    type="is-info"
                    :message="showMsgPrincipalPagador()"
                  >
                    <b-select v-model="paymentLink.PrincipalPagador" expanded>
                      <option :value="'T'">
                        {{ i18n.owner[iso] }}
                      </option>
                      <option :value="'C'">
                        {{ i18n.coOwner[iso] }}
                      </option>
                      <option :value="'B'">
                        {{ i18n.both[iso] }}
                      </option>
                      <option :value="'N'">
                        {{ i18n.nobody[iso] }}
                      </option>
                    </b-select></b-field
                  >
                </div>

                <div class="column is-2">
                  <b-field
                    :label="i18n.amount[iso]"
                    type="is-info"
                    :message="
                      disabledMonto
                        ? i18n.createPaymentsLinks__msg5[iso]
                        : checkedRows.length === 1
                        ? `${i18n.currentRate[iso]}: ${
                            checkedRows[0].Tarifa || PRICE_BASE_CONTRACT
                          }`
                        : ''
                    "
                  >
                    <b-field>
                      <p class="control">
                        <b-button label="$" disabled />
                      </p>
                      <b-numberinput
                        v-model="paymentLink.Monto"
                        :controls="false"
                        step="0.01"
                        :disabled="disabledMonto"
                      ></b-numberinput>
                      <p class="control">
                        <b-button label="USD" disabled />
                      </p>
                    </b-field>
                  </b-field>
                </div>

                <div class="column is-2">
                  <b-field
                    label="~"
                    :type="availableToSend ? 'is-success' : 'is-info'"
                    :message="
                      availableToSend
                        ? i18n.availableToSend[iso]
                        : `${i18n.pendingSend[iso]}: ${availableToSend__pending}`
                    "
                  >
                    <!-- v-if="checkedRows.length > 0 && availableToSend" -->
                    <b-button
                      v-if="checkedRows.length > 0"
                      icon-left="dollar-sign"
                      icon-pack="fas"
                      type="is-success"
                      expanded
                      @click="createPaymentsLinks"
                      :loading="createPaymentsLinks__loading"
                      >{{ i18n.createPaymentsLinks[iso] }}</b-button
                    >
                    <b-tooltip type="is-warning" position="is-right" v-else>
                      <b-button
                        icon-left="dollar-sign"
                        icon-pack="fas"
                        type="is-success"
                        expanded
                        disabled
                        :loading="createPaymentsLinks__loading"
                        >{{ i18n.createPaymentsLinks[iso] }}</b-button
                      >
                      <template v-slot:content>
                        <b>{{ i18n.createPaymentsLinks__msg4[iso] }}</b>
                      </template>
                    </b-tooltip>
                  </b-field>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
          <hr />

          <!-- Resultados -->
          <div class="columns is-multiline">
            <div class="column is-12">
              <b-table
                :data="members"
                :paginated="isPaginated"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                :default-sort-direction="defaultSortDirection"
                :pagination-rounded="isPaginationRounded"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                :bordered="isBordered"
                :striped="true"
                :narrowed="isNarrowed"
                :hoverable="true"
                :loading="loadingMembers"
                :focusable="isFocusable"
                :mobile-cards="hasMobileCards"
                checkable
                :checked-rows.sync="checkedRows"
                :is-row-checkable="checkContract"
                @check="checkChange"
              >
                <template #bottom-left>
                  <b>Total checked</b>: {{ checkedRows.length }}
                </template>

                <template #empty>
                  <div class="has-text-centered">{{ i18n.noResults[iso] }}</div>
                </template>

                <b-table-column
                  field="Numero"
                  :label="i18n.contract[iso]"
                  width="150"
                  centered
                  v-slot="props"
                >
                  {{ (props.row && props.row.Numero) || "n/a" }}
                </b-table-column>

                <b-table-column
                  field="user"
                  label="LoginID"
                  width="150"
                  centered
                  v-slot="props"
                >
                  <div
                    v-html="
                      formatTextBySearch(props.row.LoginID, 'LoginID') || `n/a`
                    "
                  ></div>
                </b-table-column>

                <b-table-column
                  field="club"
                  label="Club"
                  width="200"
                  centered
                  v-slot="props"
                >
                  {{ findClubName(props.row.ClubId) || "n/a" }}
                </b-table-column>

                <b-table-column
                  field="FechaRenovacion"
                  :label="i18n.renewalDate[iso]"
                  width="150"
                  centered
                  v-slot="props"
                >
                  <b-tag
                    :type="
                      checkSubscription(props.row) === true
                        ? 'is-success is-light'
                        : 'is-danger is-light'
                    "
                    ><b>{{
                      formatDate(props.row && props.row.FechaRenovacion) ||
                      "n/a"
                    }}</b></b-tag
                  >
                </b-table-column>

                <b-table-column
                  field="FechaCompra"
                  :label="i18n.purchaseDate[iso]"
                  width="150"
                  centered
                  v-slot="props"
                  :visible="permissions !== 'admin'"
                >
                  <b-tag type=" is-light"
                    ><b>{{
                      formatDate(props.row && props.row.FechaCompra) || "n/a"
                    }}</b></b-tag
                  >
                </b-table-column>

                <b-table-column
                  field="Idioma"
                  :label="i18n.language[iso]"
                  width="100"
                  centered
                  v-slot="props"
                  :visible="permissions === 'admin'"
                >
                  {{
                    props.row.Idioma === "SP"
                      ? i18n.spanish[iso]
                      : i18n.english[iso]
                  }}
                </b-table-column>

                <b-table-column
                  field="Agent"
                  :label="i18n.agent[iso]"
                  width="200"
                  centered
                  v-slot="props"
                >
                  <small
                    v-if="viewStaff(props.row.AgenteRenovacion)"
                    style="color: #0d68ce; font-weight: bold"
                  >
                    {{ viewStaff(props.row.AgenteRenovacion) }}
                    <span>
                      <b-tooltip
                        :label="
                          showDateAssignmentRenewal(
                            props.row.FechaAsignacionRenovacion
                          )
                        "
                        type="is-info"
                        style="cursor: pointer"
                      >
                        <b-icon
                          pack="fas"
                          icon="clock"
                          size="is-small"
                          type="is-info"
                        >
                        </b-icon>
                      </b-tooltip>
                    </span>
                  </small>
                  <small v-else style="color: #cc0f35; font-weight: bold">
                    {{ i18n.noAssignment[iso] }}
                  </small>
                </b-table-column>

                <b-table-column
                  field="user"
                  :label="i18n.status[iso]"
                  width="150"
                  centered
                  v-slot="props"
                >
                  <b-tag
                    v-if="ifCharged(props.row) === true"
                    type="is-success"
                    >{{ i18n.charged[iso] }}</b-tag
                  >
                  <b-tag v-else type="is-danger">{{
                    i18n.noCharged[iso]
                  }}</b-tag>
                </b-table-column>

                <b-table-column
                  field="user"
                  :label="i18n.submitted[iso]"
                  width="80"
                  centered
                  v-slot="props"
                  v-if="allPermissions.includes('renewals-customer-portfolio')"
                >
                  {{ checkEnvios(props.row.payLinks, props.row.Id) }}
                </b-table-column>

                <b-table-column
                  label="Actions"
                  v-slot="props"
                  centered
                  width="170"
                >
                  <b-tooltip type="is-primary is-light">
                    <b-button
                      icon-left="eye"
                      icon-pack="fas"
                      size="is-small"
                      type="is-primary is-light"
                      @click="goPathNewTab(props.row.Id)"
                      style="margin-right: 10px"
                    ></b-button>
                    <template v-slot:content>
                      <b>{{ i18n.viewContract[iso] }}</b></template
                    >
                  </b-tooltip>
                  <b-tooltip
                    :type="
                      props.row.payLinks && props.row.payLinks.length > 0
                        ? 'is-success is-light'
                        : 'is-danger is-light'
                    "
                    v-if="
                      allPermissions.includes('renewals-customer-portfolio')
                    "
                  >
                    <b-button
                      v-if="props.row.payLinks && props.row.payLinks.length > 0"
                      icon-left="dollar-sign"
                      icon-pack="fas"
                      size="is-small"
                      type="is-success is-light"
                      style="margin-right: 10px"
                      @click="openModalPaymentLinks(props.row.payLinks)"
                    ></b-button>
                    <b-button
                      v-else
                      icon-left="dollar-sign"
                      icon-pack="fas"
                      size="is-small"
                      type="is-danger is-light"
                      style="margin-right: 10px"
                      disabled
                    ></b-button>
                    <template v-slot:content>
                      <b
                        v-if="
                          props.row.payLinks && props.row.payLinks.length > 0
                        "
                        >{{ i18n.paymentsLinks[iso] }}</b
                      >
                      <b v-else>{{ i18n.notAvailable[iso] }}</b>
                    </template>
                  </b-tooltip>
                </b-table-column>

                <template #footer>
                  <div class="columns">
                    <div class="column is-11"></div>

                    <div class="column is-1">
                      <b-field :label="i18n.quantity[iso]">
                        <!-- @input="savePerPage" -->
                        <b-select v-model="perPage" expanded>
                          <option :value="5">5</option>
                          <option :value="10">10</option>
                          <option :value="20">20</option>
                          <option :value="50">50</option>
                          <option :value="100">100</option>
                          <option :value="300">300</option>
                          <option :value="500">500</option>
                          <option :value="1000">1000</option>
                        </b-select>
                      </b-field>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </b-tab-item>

        <!-- Estadisticas -->
        <b-tab-item>
          <template #header>
            <b-icon icon="chart-bar" pack="fas" type="is-info"></b-icon>
            <b>{{ i18n.statistics[iso] }}</b>
          </template>
          <div class="columns is-multiline">
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.assignedAt[iso]">
                <b-datepicker
                  v-model="statsFilters.FechaAsignacion"
                  placeholder="DD/MM/YYYY"
                  type="month"
                  :min-date="minDate__FechaAsignacionStats"
                  :max-date="maxDate__FechaAsignacionStats"
                  :locale="iso"
                  range
                  icon="calendar"
                  icon-pack="fas"
                  :icon-right="
                    statsFilters.FechaAsignacion.length > 0 ? 'times' : ''
                  "
                  icon-right-clickable
                  @icon-right-click="statsFilters.FechaAsignacion = []"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-2">
              <b-button
                icon-left="search"
                icon-pack="fas"
                type="is-info"
                @click="statsAdmin()"
                expanded
                :loading="statsAdmin__loading"
                >{{ i18n.search[iso] }}</b-button
              >
            </div>
          </div>
          <hr />
          <div class="columns is-multiline" v-for="stat in allStats">
            <div class="column is-12">
              <div class="content">
                <h3>{{ findAgentName(stat.name) }}</h3>
              </div>
            </div>
            <div class="column is-12">
              <b-table
                :data="stat.data"
                :bordered="isBordered"
                :narrowed="isNarrowed"
                :hoverable="true"
                :focusable="isFocusable"
                :mobile-cards="hasMobileCards"
                :row-class="
                  (row, index) => (row.Fecha === 'Total' ? 'is-info' : '')
                "
              >
                <!-- <template #detail="props">
                  <b-table
                    :data="[props.row]"
                    :bordered="true"
                    :narrowed="isNarrowed"
                    :hoverable="true"
                    :focusable="isFocusable"
                  >
                    <b-table-column
                      field="Regular"
                      :label="'Regular'"
                      width="150"
                      centered
                      v-slot="props"
                    >
                      {{ props.row.Regular }}
                    </b-table-column>
                    <b-table-column
                      field="Multiple"
                      :label="'Multiple'"
                      width="150"
                      centered
                      v-slot="props"
                    >
                      {{ props.row.Multiple }}
                    </b-table-column>

                    <b-table-column
                      field="NMF"
                      :label="'NMF'"
                      width="150"
                      centered
                      v-slot="props"
                    >
                      {{ props.row.NMF }}
                    </b-table-column>
                  </b-table>
                </template> -->

                <b-table-column
                  field="Fecha"
                  :label="i18n.assignedAt[iso]"
                  width="250"
                  centered
                  v-slot="props"
                >
                  <span v-if="props.row.Fecha === 'Total'"><b>Total</b></span>
                  <span v-else>{{ props.row.Fecha || "" }}</span>
                </b-table-column>

                <b-table-column
                  field="FechaCartera"
                  :label="i18n.portfolioDate[iso]"
                  width="200"
                  centered
                  v-slot="props"
                >
                  <span>{{ props.row.FechaCartera || "" }}</span>
                </b-table-column>

                <b-table-column
                  field="Asignaciones"
                  :label="i18n.assignments[iso]"
                  width="150"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Asignaciones }}
                </b-table-column>

                <b-table-column
                  field="Canceladas"
                  :label="i18n.canceled[iso]"
                  width="150"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Canceladas }}
                </b-table-column>

                <b-table-column
                  field="Activas"
                  :label="i18n.actives[iso]"
                  width="150"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Activas }}
                </b-table-column>

                <b-table-column
                  field="Cobradas"
                  :label="i18n.charged[iso]"
                  width="150"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Cobradas }}
                </b-table-column>

                <b-table-column
                  field="NoCobradas"
                  :label="i18n.noCharged[iso]"
                  width="200"
                  centered
                  v-slot="props"
                >
                  {{ props.row.NoCobradas }}
                </b-table-column>

                <b-table-column
                  field="Renovado"
                  :label="i18n.renovated[iso]"
                  width="150"
                  centered
                  v-slot="props"
                >
                  $ {{ props.row.Renovado }} USD
                </b-table-column>

                <b-table-column
                  field="EFI"
                  :label="'EFI'"
                  width="150"
                  centered
                  v-slot="props"
                >
                  % {{ props.row.EFI }}
                </b-table-column>

                <b-table-column
                  field="Regular"
                  :label="'Regular'"
                  width="150"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Regular }}
                </b-table-column>
                <b-table-column
                  field="Multiple"
                  :label="'Multiple'"
                  width="150"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Multiple }}
                </b-table-column>

                <b-table-column
                  field="NMF"
                  :label="'NMF'"
                  width="150"
                  centered
                  v-slot="props"
                >
                  {{ props.row.NMF }}
                </b-table-column>
              </b-table>
            </div>
            <div class="column is-12"><hr /></div>
          </div>
        </b-tab-item>

        <!-- Cierre de mes -->
        <b-tab-item
          :visible="permissions === 'admin'"
          :disabled="tabResults !== 0"
        >
          <template #header>
            <b-icon icon="calendar-times" pack="fas" type="is-danger"></b-icon>
            <b>{{ i18n.closePortfolio[iso] }}</b>
          </template>
          <div class="columns is-multiline">
            <div class="column is-2"></div>
            <div class="column is-8">
              <b-message type="is-warning" size="is-small">
                <b>{{ i18n.alert[iso] }}: {{ i18n.closeMonth__msg1[iso] }}</b>
              </b-message>
            </div>
            <div class="column is-2"></div>

            <div class="column is-3"></div>
            <div class="column is-2">
              <b-field label-position="on-border" :label="i18n.assignedAt[iso]">
                <b-datepicker
                  v-model="filtersClosePortfolio.FechaAsignacion"
                  type="month"
                  icon="calendar"
                  icon-pack="fas"
                  trap-focus
                  icon-right-clickable
                  @icon-right-click="
                    filtersClosePortfolio.FechaAsignacion = null
                  "
                  :icon-right="
                    filtersClosePortfolio.FechaAsignacion ? 'times' : ''
                  "
                  :locale="iso"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field
                label-position="on-border"
                :label="i18n.portfolioDate[iso]"
              >
                <b-datepicker
                  v-model="filtersClosePortfolio.FechaCartera"
                  type="month"
                  icon="calendar"
                  icon-pack="fas"
                  trap-focus
                  icon-right-clickable
                  @icon-right-click="filtersClosePortfolio.FechaCartera = null"
                  :icon-right="
                    filtersClosePortfolio.FechaCartera ? 'times' : ''
                  "
                  :locale="iso"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label-position="on-border" :label="i18n.agent[iso]">
                <b-select expanded v-model="filtersClosePortfolio.Agente">
                  <option :value="null">{{ i18n.all[iso] }}</option>
                  <option
                    v-for="user in staff"
                    :value="user.Usuario"
                    :key="user._id"
                  >
                    {{ user.Nombre }} {{ user.Apellido }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-3"></div>

            <div class="column is-3"></div>
            <div class="column is-6">
              <!-- <b-field
                :label="`${i18n.closeMonth__msg6[iso].toUpperCase()}: ${
                  currentMonthBeforeMonth || ''
                }`"
              >
                
              </b-field> -->
              <b-button
                icon-left="calendar-times"
                icon-pack="fas"
                type="is-danger is-light"
                expanded
                @click="closeMonth"
                :loading="closeMonth__loading"
                >{{ i18n.closePortfolio[iso] }}</b-button
              >
            </div>
            <div class="column is-3"></div>
          </div>
        </b-tab-item>
      </b-tabs>
    </section>

    <!-- Modal de enlaces de pago -->
    <b-modal v-model="ModalPaymentLinkRenewal" :width="850" :can-cancel="['x']">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">{{ i18n.paymentsLinks[iso] }}</p>
          <button
            class="card-header-icon"
            aria-label="more options"
            @click="closeModalPaymentLinks"
          >
            <b-icon pack="fas" icon="times" type="is-danger" size="is-small">
            </b-icon>
          </button>
        </header>
        <div class="card-content">
          <div class="content">
            <b-table
              :data="payLinksSelected"
              :bordered="isBordered"
              :striped="true"
              :narrowed="isNarrowed"
              :hoverable="true"
              :focusable="isFocusable"
              :mobile-cards="hasMobileCards"
              detailed
              :show-detail-icon="false"
              :opened-detailed="payLinksSelectedOpenDetails"
              detail-key="Id"
            >
              <template #detail="props">
                <b-table
                  :data="props.row.PaymentId ? [props.row.PaymentId] : []"
                  :bordered="true"
                  :striped="false"
                  :narrowed="isNarrowed"
                  :hoverable="true"
                  :focusable="isFocusable"
                >
                  <b-table-column
                    field="stripeId"
                    :label="i18n.reference[iso]"
                    width="150"
                    centered
                    v-slot="propsPay"
                  >
                    <small>{{ propsPay.row.stripeId || "" }}</small>
                  </b-table-column>

                  <b-table-column
                    field="type"
                    :label="i18n.method[iso]"
                    width="150"
                    centered
                    v-slot="propsPay"
                  >
                    <small>{{ propsPay.row.type || "" }}</small>
                  </b-table-column>

                  <b-table-column
                    field="createdAtMs"
                    :label="i18n.date[iso]"
                    width="150"
                    centered
                    v-slot="propsPay"
                  >
                    <small>{{
                      propsPay.row.createdAtMs
                        ? parseDate(propsPay.row.createdAtMs)
                        : ""
                    }}</small>
                  </b-table-column>
                  <b-table-column
                    field="amount"
                    :label="i18n.amount[iso]"
                    width="150"
                    centered
                    v-slot="propsPay"
                  >
                    <small>$ {{ propsPay.row.amount || "" }} USD</small>
                  </b-table-column>
                </b-table>
              </template>

              <b-table-column
                field="AniosRenovacion"
                :label="i18n.years[iso]"
                width="100"
                centered
                v-slot="props"
              >
                <span v-if="props.row.AniosRenovacion === 99"
                  >No more fees</span
                >
                <span v-else>{{ props.row.AniosRenovacion || "n/a" }}</span>
              </b-table-column>

              <b-table-column
                field="Monto"
                :label="i18n.amount[iso]"
                width="100"
                centered
                v-slot="props"
              >
                <span>{{ props.row.Monto || 0 }}</span>
              </b-table-column>

              <b-table-column
                field="Estado"
                :label="i18n.status[iso]"
                width="100"
                centered
                v-slot="props"
              >
                <!-- <span>{{ props.row.Estado || "" }}</span> -->
                <span v-if="props.row.Estado === 'sent'"
                  ><b-tag type="is-warning">{{ i18n.sent[iso] }}</b-tag></span
                >
                <span
                  v-if="props.row.Estado === 'paid'"
                  style="cursor: pointer"
                  @click="props.toggleDetails(props.row)"
                  ><b-tag
                    type="is-success"
                    :icon="
                      payLinksSelectedOpenDetails.includes(props.row.Id)
                        ? 'eye'
                        : 'eye-slash'
                    "
                    icon-pack="fas"
                    >{{ i18n.paid[iso] }}</b-tag
                  >
                </span>
                <span v-if="props.row.Estado === 'pending'"
                  ><b-tag type="is-warning">{{
                    i18n.pending[iso]
                  }}</b-tag></span
                >
                <span v-if="props.row.Estado === 'canceled'"
                  ><b-tag type="is-danger">{{
                    i18n.cancelled[iso]
                  }}</b-tag></span
                >
                <span v-if="props.row.Estado === 'created'"
                  ><b-tag type="is-dark">{{ i18n.created[iso] }}</b-tag></span
                >
                <span v-if="props.row.Estado === 'expired'"
                  ><b-tag type="is-danger">{{ i18n.expired[iso] }}</b-tag></span
                >
                <span v-if="props.row.Estado === 'open'"
                  ><b-tag type="is-info">{{ i18n.opened[iso] }}</b-tag></span
                >
              </b-table-column>

              <b-table-column
                field="FechaCreacion"
                :label="i18n.creationDate[iso]"
                width="100"
                centered
                v-slot="props"
              >
                <span>{{ formatDate(props.row.FechaCreacion) }}</span>
              </b-table-column>

              <b-table-column
                label="Actions"
                v-slot="props"
                centered
                width="170"
              >
                <b-tooltip type="is-info is-light">
                  <b-button
                    icon-left="copy"
                    icon-pack="fas"
                    size="is-small"
                    type="is-info is-light"
                    style="margin-right: 10px"
                    @click="copyPaymentLink(props.row.Id)"
                    :disabled="
                      props.row.Estado === 'paid' ||
                      props.row.Estado === 'expired' ||
                      props.row.Estado === 'canceled'
                    "
                  ></b-button>
                  <template v-slot:content>
                    <b>{{ i18n.copyPaymentLink[iso] }}</b></template
                  >
                </b-tooltip>
                <b-tooltip
                  type="is-success is-light"
                  :always="isShowOptionsSendPayments && true"
                  :active="isShowOptionsSendPayments === props.row.Id"
                >
                  <!-- @click="reSendPaymentLink(props.row.Id)" -->
                  <b-button
                    icon-left="paper-plane"
                    icon-pack="fas"
                    size="is-small"
                    type="is-success is-light"
                    style="margin-right: 10px"
                    @click="showOptionsSendPayments(props.row.Id)"
                    :disabled="
                      props.row.Estado === 'paid' ||
                      props.row.Estado === 'expired' ||
                      props.row.Estado === 'canceled'
                    "
                  ></b-button>
                  <template v-slot:content>
                    <!-- <b>{{ i18n.sendLinkPayment[iso] }}</b> -->
                    <b-tooltip :label="i18n.sendToOwner[iso]" type="is-success">
                      <b-button
                        icon-left="paper-plane"
                        icon-pack="fas"
                        size="is-small"
                        type="is-success"
                        @click="reSendPaymentLink(props.row.Id, 'T')"
                        :loading="
                          loadingResendPaymentLink === props.row.Id + 'T'
                        "
                        style="margin-right: 10px"
                      ></b-button>
                    </b-tooltip>
                    <b-tooltip :label="i18n.sendToCoOwner[iso]" type="is-info">
                      <b-button
                        icon-left="paper-plane"
                        icon-pack="fas"
                        size="is-small"
                        type="is-info"
                        @click="reSendPaymentLink(props.row.Id, 'C')"
                        :loading="
                          loadingResendPaymentLink === props.row.Id + 'C'
                        "
                        style="margin-right: 10px"
                      ></b-button>
                    </b-tooltip>
                    <b-tooltip :label="i18n.sentToBoth[iso]" type="is-primary">
                      <b-button
                        icon-left="paper-plane"
                        icon-pack="fas"
                        size="is-small"
                        type="is-primary"
                        @click="reSendPaymentLink(props.row.Id, 'B')"
                        :loading="
                          loadingResendPaymentLink === props.row.Id + 'B'
                        "
                      ></b-button>
                    </b-tooltip>
                  </template>
                </b-tooltip>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Modal antes de cerrar el mes -->
    <b-modal v-model="ModalCheckCloseMonth" :width="950" scroll="keep">
      <div style="height: 600px; max-height: 600px; font-size: 12px">
        <b-button
          icon-left="calendar-times"
          icon-pack="fas"
          type="is-danger is-light"
          @click="closeMonth__exec"
          expanded
          :loading="closeMonth__loading"
          inverted
          ><b
            >{{ i18n.closeMonth__msg5[iso].toUpperCase() }}
            <!-- {{ currentMonthBeforeMonth }} -->
          </b>
        </b-button>
        <b-table
          :data="membersCloseMonth"
          :bordered="isBordered"
          :striped="true"
          :narrowed="isNarrowed"
          :hoverable="true"
          :focusable="isFocusable"
          :mobile-cards="hasMobileCards"
          :loading="closeMonth__loading"
        >
          <template #empty>
            <div class="has-text-centered">{{ i18n.noData[iso] }}</div>
          </template>

          <b-table-column
            field="Numero"
            :label="'-'"
            width="30"
            centered
            v-slot="props"
          >
            {{ props.index + 1 }}
          </b-table-column>

          <b-table-column
            field="Numero"
            :label="i18n.contract[iso]"
            width="150"
            centered
            v-slot="props"
          >
            {{ (props.row && props.row.Numero) || "n/a" }}
          </b-table-column>

          <b-table-column
            field="user"
            label="LoginID"
            width="150"
            centered
            v-slot="props"
          >
            <div
              v-html="formatTextBySearch(props.row.LoginID, 'LoginID') || `n/a`"
            ></div>
          </b-table-column>

          <b-table-column
            field="club"
            label="Club"
            width="200"
            centered
            v-slot="props"
          >
            {{ findClubName(props.row.ClubId) || "n/a" }}
          </b-table-column>

          <b-table-column
            field="FechaRenovacion"
            :label="i18n.renewalDate[iso]"
            width="150"
            centered
            v-slot="props"
          >
            <b-tag
              :type="
                checkSubscription(props.row) === true
                  ? 'is-success is-light'
                  : 'is-danger is-light'
              "
              ><b>{{
                formatDate(props.row && props.row.FechaRenovacion) || "n/a"
              }}</b></b-tag
            >
          </b-table-column>

          <b-table-column
            field="Agent"
            :label="i18n.agent[iso]"
            width="200"
            centered
            v-slot="props"
          >
            <small
              v-if="viewStaff(props.row.AgenteRenovacion)"
              style="color: #0d68ce; font-weight: bold"
            >
              {{ viewStaff(props.row.AgenteRenovacion) }}
              <span>
                <b-tooltip
                  :label="
                    showDateAssignmentRenewal(
                      props.row.FechaAsignacionRenovacion
                    )
                  "
                  type="is-info"
                  style="cursor: pointer"
                >
                  <b-icon
                    pack="fas"
                    icon="clock"
                    size="is-small"
                    type="is-info"
                  >
                  </b-icon>
                </b-tooltip>
              </span>
            </small>
            <small v-else style="color: #cc0f35; font-weight: bold">
              {{ i18n.noAssignment[iso] }}
            </small>
          </b-table-column>

          <b-table-column
            field="user"
            :label="i18n.status[iso]"
            width="150"
            centered
            v-slot="props"
          >
            <b-tag v-if="ifCharged(props.row) === true" type="is-success">{{
              i18n.charged[iso]
            }}</b-tag>
            <b-tag v-else type="is-danger">{{ i18n.noCharged[iso] }}</b-tag>
          </b-table-column>
        </b-table>
        <!-- <b-button
          icon-left="calendar-times"
          icon-pack="fas"
          type="is-danger is-light"
          @click="closeMonth__exec"
          expanded
          :loading="closeMonth__loading"
          active
          ><b
            >{{ i18n.closeMonth__msg1[iso].toUpperCase() }}:
            {{ currentMonthBeforeMonth }}</b
          ></b-button
        > -->
      </div>

      <!-- <div class="card" style="height: 600px; max-height: 600px;">
        <header class="card-header">
          <p class="card-header-title">{{ i18n.paymentsLinks[iso] }}</p>
          <button
            class="card-header-icon"
            aria-label="more options"
            @click="ModalCheckCloseMonth = false"
          >
            <b-icon pack="fas" icon="times" type="is-danger" size="is-small">
            </b-icon>
          </button>
        </header>
        <div class="card-content">
          <div class="content">
            
          </div>
        </div>
        <footer class="card-footer">
          <a href="#" class="card-footer-item">Save</a>
          <a href="#" class="card-footer-item">Edit</a>
          <a href="#" class="card-footer-item">Delete</a>
        </footer>
      </div> -->
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../../utils/i18n";

import ModalEditMember from "../ModalEditMember.vue";
import ModalProfileMember from "../Membership/MemberProfile.vue";
// import ModalCommentMember from "./ModalCommentMember.vue";

const today = new Date();

const PRICE_BASE_CONTRACT = 199;

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,
      total: 0,
      loadingExportMembers: false,

      //i18n
      i18n,
      iso: null,

      //Permissions
      permissions: "",
      allPermissions: [],

      //Filters
      tabFilters: 0,
      tabActions: 0,
      searchMembers: null,
      passValidateSearch: false,
      lastSearchMembers: null,
      clubesName: [],
      staff: [],
      allUsers: [],
      selectedStaff: [],
      loadingClubes: false,
      itemsFilters: [],
      checkedRows: [],
      advancedFilters: {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaProcesable: [],
        FechaRenovacion: [],
        FechaCancelacion: [],
        FechaAsignacionRenovacion: [],
        FechaCompra: [],
        club: null,
        ClubId: [],
        status: null,
        membershipStatus: null,
        onlyHeadlines: false,
        language: null,
        contractStatus: null,
        staffRenewal: null,
        paymentStatusRenewal: null,
        noAssignmentRenewal: null,
        agent: null,
        Idioma: null,
      },
      validateAdvancedFilters: {
        FechaRegistro: false,
        FechaProcesamiento: false,
        FechaCancelacion: false,
        FechaRenovacion: false,
        FechaCompra: false,
        EstadoMembresia: false,
        EstadoContrato: false,
      },

      /**
       * Members
       */

      members: [],
      loadingMembers: false,

      /**
       * Modal
       */

      isActiveModalEditMember: false,
      dataModalEditMember: null,
      isActiveModalCommentMember: false,
      memberIdModalCommentMember: null,

      /**
       * Dates
       */
      date: new Date(),
      minDate: new Date(
        today.getFullYear() - 80,
        today.getMonth(),
        today.getDate()
      ),
      maxDate: new Date(
        today.getFullYear() + 18,
        today.getMonth(),
        today.getDate()
      ),
      minDate__FechaAsignacionRenovacion: new Date(
        today.getFullYear() - 80,
        today.getMonth(),
        today.getDate()
      ),
      maxDate__FechaAsignacionRenovacion: new Date(
        today.getFullYear() + 18,
        today.getMonth(),
        today.getDate()
      ),
      minDate__FechaAsignacionStats: new Date(
        today.getFullYear() - 80,
        today.getMonth(),
        today.getDate()
      ),
      maxDate__FechaAsignacionStats: new Date(
        today.getFullYear() + 18,
        today.getMonth(),
        today.getDate()
      ),

      ModalProfileMemberShow: false,
      removeAssignmentsFetch__loading: false,
      assignStaffToContractsFetch__loading: false,
      ModalPaymentLinkRenewal: false,
      payLinksSelected: [],
      payLinksSelectedOpenDetails: [],

      //Pagos
      paymentLink: {
        Years: 1,
        PrincipalPagador: "T",
        Monto: 199,
      },
      disabledYears: false,
      disabledMonto: false,
      PRICE_BASE_CONTRACT,
      createPaymentsLinks__loading: false,
      loadingResendPaymentLink: null,
      availableToSend: true,
      availableToSend__pending: 0,
      availableToSend__finalized: 0,
      currentMonth: null,
      currentMonthBeforeMonth: null,
      currentMonthDate: null,
      closeMonth__loading: false,
      allStats: [],
      tabResults: 0,
      statsAdmin__loading: false,
      statsFilters: {
        FechaAsignacion: [],
        agent: null,
      },
      exportDataToCsv__loading: false,
      ModalCheckCloseMonth: false,
      membersCloseMonth: [],
      FechaRenovacionMes: null,
      FechaAsignacionRenovacionMes: null,
      isShowOptionsSendPayments: false,
      checkSelectedAllClubsInFilters: false,
      clubesRgx: [],
      filtersClosePortfolio: {
        FechaAsignacion: null,
        FechaCartera: null,
        Agente: null,
      },
    };
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    await this.checkMonth();

    //PerPage
    // const perPageLocalStorage = localStorage.getItem("perPageReserves");
    // if (!perPageLocalStorage) this.savePerPage();
    // else this.perPage = perPageLocalStorage;

    //Permissions
    this.permissions = localStorage.getItem("role");
    this.allPermissions = localStorage.getItem("permissions")
      ? JSON.parse(localStorage.getItem("permissions"))
      : [];

    if (!this.currentMonthDate) this.currentMonthDate = moment().valueOf();

    const date1 = moment()
      .add(1, "month")
      .subtract(1, "year")
      .startOf("month");
    const date2 = moment().add(1, "month").endOf("month");
    this.statsFilters.FechaAsignacion = [new Date(date1), new Date(date2)];

    await this.listAllClubes();
    await this.listAllUsers();
    await this.listAllUsersStaff();

    if (this.permissions !== "admin") {
      this.tabActions = 1;
      this.advancedFilters.staffRenewal = localStorage.getItem("user");
      this.advancedFilters.agent = localStorage.getItem("user");
      this.advancedFilters.noAssignmentRenewal = 1;

      this.statsFilters.agent = localStorage.getItem("user");
      await this.advancedSearch(false);
    } else {
      await this.statsAdmin();
    }
  },
  components: {
    ModalEditMember,
    ModalProfileMember,
    // ModalCommentMember,
  },
  methods: {
    async listAllMembers__deprecated(
      filters = {},
      isState = false,
      state = {}
    ) {
      try {
        console.log("isState", isState);
        if (isState) {
          this.members = state.data;
          this.total = state.total;
          this.lastSearchMembers = state.lastSearchMembers;
          this.itemsFilters = state.itemsFilters;
        } else {
          this.loadingMembers = true;
          let query = `?limit=${this.perPage}&page=${this.currentPage}&genReplicate=n`;
          const { data } = await axios.post(
            `${API_URL}/catcher/members/list-all${query}`,
            filters
          );
          console.log("members", data);
          this.members = data?.members || [];
          this.total = data.paginate.total;
          this.lastSearchMembers = `${this.searchMembers}`;
          this.itemsFilters = data?.itemsFilters;
          this.loadingMembers = false;
          this.checkedRows = [];

          /**
           * State
           */
          const stateMembers = {
            data: this.members,
            exp: moment().add(20, "minute").valueOf(),
            total: this.total,
            lastSearchMembers: this.lastSearchMembers,
            itemsFilters: this.itemsFilters,
          };
          localStorage.setItem("members", JSON.stringify(stateMembers));
        }
      } catch (err) {
        this.loadingMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos",
          type: "is-danger",
        });
      }
    },
    async listAllMembers(filters = {}, isState = false, state = {}) {
      try {
        console.log("isState", isState);
        // if (isState) {
        if (false) {
          this.members = state.data;
          this.total = state.total;
          this.lastSearchMembers = state.lastSearchMembers;
          this.itemsFilters = state.itemsFilters;
        } else {
          this.loadingMembers = true;
          // let query = `?limit=${this.perPage}&page=${this.currentPage}&genReplicate=n`;

          // if (this.permissions !== "admin") {
          //   this.advancedFilters.FechaAsignacionRenovacion = [
          //     this.minDate__FechaAsignacionRenovacion,
          //     this.maxDate__FechaAsignacionRenovacion,
          //   ];
          // }

          const { data } = await axios.post(
            `${API_URL}/renewals/contracts/list`,
            filters
          );
          // console.log("members", data);
          this.members = data?.contracts || [];
          this.total = data?.paginate?.total || data?.contracts.length || 0;
          // this.lastSearchMembers = `${this.searchMembers}`;
          // this.itemsFilters = data?.itemsFilters;
          this.statsAdmin();
          this.checkAvailableToSend();

          this.loadingMembers = false;
          this.checkedRows = [];

          /**
           * State
           */
          // const stateMembers = {
          //   data: this.members,
          //   exp: moment().add(20, "minute").valueOf(),
          //   total: this.total,
          //   lastSearchMembers: this.lastSearchMembers,
          //   itemsFilters: this.itemsFilters,
          // };
          // localStorage.setItem("members", JSON.stringify(stateMembers));
        }
      } catch (err) {
        this.loadingMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos",
          type: "is-danger",
        });
      }
    },
    async checkAvailableToSend() {
      try {
        const { data } = await axios.get(
          `${API_URL}/renewals/check-renewals-send/${localStorage.getItem(
            "user"
          )}`
        );

        this.availableToSend = data?.pass;
        this.availableToSend__pending = data?.pending;
        this.availableToSend__finalized = data?.finalized;
      } catch (err) {
        console.log(err);
      }
    },
    async checkMonth() {
      try {
        const { data } = await axios.get(
          `${API_URL}/renewals/check-month/portfolio`
        );

        if (data?.settings?.MesAbiertoRenovaciones) {
          this.currentMonthDate = data?.settings?.MesAbiertoRenovaciones;
          this.currentMonth = moment(data?.settings?.MesAbiertoRenovaciones)
            .locale(this.iso)
            .format("MMMM YYYY");
          this.currentMonthBeforeMonth = moment(
            data?.settings?.MesAbiertoRenovaciones
          )
            .subtract(1, "month")
            .locale(this.iso)
            .format("MMMM YYYY")
            .toUpperCase();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async closeMonth() {
      // this.$buefy.dialog.confirm({
      //   title: this.i18n.closeMonth[this.iso],
      //   message: this.i18n.closeMonth__msg3[this.iso],
      //   cancelText: this.i18n.cancel[this.iso],
      //   confirmText: this.i18n.accept[this.iso],
      //   type: "is-danger is-light",
      //   onConfirm: () => this.closeMonth__exec(),
      // });
      try {
        if (
          !this.filtersClosePortfolio.FechaAsignacion &&
          !this.filtersClosePortfolio.FechaCartera
        ) {
          this.$buefy.dialog.alert({
            title: this.i18n.closeMonth__msg2[this.iso],
            message: this.i18n.closeMonth__msg7[this.iso],
            type: "is-danger",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        } else {
          this.closeMonth__loading = true;

          let filtersClosePortfolio = {};

          if (this.filtersClosePortfolio.FechaAsignacion) {
            const date1 = moment(this.filtersClosePortfolio.FechaAsignacion)
              .startOf("month")
              .valueOf();
            const date2 = moment(this.filtersClosePortfolio.FechaAsignacion)
              .endOf("month")
              .valueOf();

            filtersClosePortfolio.FechaAsignacionRenovacion = [date1, date2];
          }

          if (this.filtersClosePortfolio.FechaCartera) {
            const date1 = moment(this.filtersClosePortfolio.FechaCartera)
              .startOf("month")
              .valueOf();
            const date2 = moment(this.filtersClosePortfolio.FechaCartera)
              .endOf("month")
              .valueOf();

            filtersClosePortfolio.FechaRenovacionPortfolio = [date1, date2];
          }

          if (this.filtersClosePortfolio.Agente) {
            filtersClosePortfolio.staffRenewal =
              this.filtersClosePortfolio.Agente;
          }

          const { data } = await axios.post(
            `${API_URL}/renewals/contracts/list`,
            {
              ...filtersClosePortfolio,
              // FechaAsignacionRenovacion: [closeMonth1, closeMonth2],
            }
          );
          this.membersCloseMonth = data?.contracts || [];
          this.ModalCheckCloseMonth = true;
          this.closeMonth__loading = false;
        }
      } catch (err) {
        this.closeMonth__loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos",
          type: "is-danger",
        });
      }
    },
    async closeMonth__exec() {
      try {
        this.closeMonth__loading = true;

        const { data } = await axios.post(
          `${API_URL}/renewals/close-month/portfolio`,
          {
            ...this.filtersClosePortfolio,
            updatedBy: localStorage.getItem("user"),
          }
        );

        this.checkMonth();
        this.advancedSearch(false);
        this.ModalCheckCloseMonth = false;
        this.closeMonth__loading = false;

        this.filtersClosePortfolio.FechaAsignacion = null;
        this.filtersClosePortfolio.FechaCartera = null;
        this.filtersClosePortfolio.Agente = null;

        this.$buefy.toast.open({
          message: this.i18n.closeMonth__msg4[this.iso],
          type: "is-success",
        });
      } catch (err) {
        this.closeMonth__loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: this.i18n.closeMonth__msg2[this.iso],
          type: "is-danger",
        });
      }
    },
    savePerPage() {
      localStorage.setItem("perPageReserves", this.perPage);
      // this.listAllMembers();
    },
    validateSearch(text) {
      // return true;
      // this.passValidateSearch = /^[A-Za-z0-9]+$/gi.test(text);
      this.passValidateSearch = true;
    },
    formatTextBySearch(text, field) {
      if (this.itemsFilters.includes(field)) {
        if (this.lastSearchMembers && text && text !== "") {
          const rgx = new RegExp(this.lastSearchMembers, "ig");
          const insertHtml = (match) => {
            return `<b style="color: #167df0;">${match}</b>`;
          };
          const __name = text.replace(rgx, insertHtml);

          return __name;
        } else {
          return text;
        }
      } else {
        return text;
      }
    },
    openModalEditMember(member) {
      this.dataModalEditMember = member;
      this.isActiveModalEditMember = true;
    },
    restorePassword() {
      this.$buefy.dialog.confirm({
        title: this.i18n.resetPassword[this.iso],
        message: this.i18n.resetPassword__confirm[this.iso],
        cancelText: this.i18n.cancel[this.iso],
        confirmText: this.i18n.accept[this.iso],
        type: "is-info is-light",
        onConfirm: () =>
          this.$buefy.toast.open(
            this.i18n.resetPassword__confirm__success[this.iso]
          ),
      });
    },
    openModalCommentMember(memberId) {
      this.memberIdModalCommentMember = memberId;
      this.isActiveModalCommentMember = true;
    },
    goPath(path) {
      this.$router.push(path);
    },
    goPathNewTab(Id) {
      const routeData = this.$router.resolve({
        name: "membershipProfile",
        query: { ContratoId: Id },
      });
      window.open(routeData.href, "_blank");
    },
    changeTab() {
      // localStorage.setItem("tabFilters", this.tabFilters);
    },
    async listAllClubes() {
      try {
        this.loadingClubes = true;
        const { data } = await axios.post(`${API_URL}/club/list`, {
          select: "Nombre Id",
        });
        this.clubesName = data?.clubes || [];
        this.clubesRgx = [...this.clubesName];
        this.loadingClubes = false;
      } catch (err) {
        this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de clubes",
          type: "is-danger",
        });
      }
    },
    advancedSearch(isExport = false) {
      if (isExport) {
        this.exportAllMembers(this.advancedFilters);
      } else {
        this.currentPage = 1;
        this.listAllMembers(this.advancedFilters);
      }
    },
    checkAdvancedSearch() {
      let result = false;
      for (let i in this.advancedFilters) {
        if (this.advancedFilters[i]) result = true;
      }
      return result;
    },
    clearAdvancedSearch() {
      if (this.permissions !== "admin") {
        this.advancedFilters = {
          Nombre: null,
          ApellidoPaterno: null,
          Email: null,
          LoginID: null,
          nroContract: null,
          FechaRegistro: [],
          FechaCancelado: [],
          FechaRenovacion: [],
          club: null,
          status: null,
          onlyHeadlines: null,
          language: null,
          contractStatus: null,
          paymentStatusRenewal: null,
          noAssignmentRenewal: null,
          staffRenewal: null,
          agent: null,
          ClubId: [],
        };
        this.advancedFilters.staffRenewal = localStorage.getItem("user");
        this.advancedFilters.agent = localStorage.getItem("user");
        // this.advancedFilters.FechaAsignacionRenovacion = [
        //   this.minDate__FechaAsignacionRenovacion,
        //   this.maxDate__FechaAsignacionRenovacion,
        // ];
      } else {
        this.advancedFilters = {
          Nombre: null,
          ApellidoPaterno: null,
          Email: null,
          LoginID: null,
          nroContract: null,
          FechaRegistro: [],
          FechaCancelado: [],
          FechaRenovacion: [],
          FechaAsignacionRenovacion: [],
          club: null,
          status: null,
          onlyHeadlines: null,
          language: null,
          contractStatus: null,
          staffRenewal: null,
          paymentStatusRenewal: null,
          noAssignmentRenewal: null,
          agent: null,
          Idioma: null,
          ClubId: [],
        };
      }

      // this.advancedSearch();
    },
    getMembersStatic(data) {
      let result = [];
      result.push({
        FullNombre: `${data.Nombre} ${data.ApellidoPaterno}`,
        Nombre: data.Nombre,
        ApellidoPaterno: data.ApellidoPaterno,
        Telefono: data.Telefono,
        Email: data.Email,
        Language: data.ContratoId?.Idioma,
        TipoSocioId: data.TipoSocio,
      });

      if (data?.coOwner)
        result.push({
          FullNombre: `${data.coOwner.Nombre} ${data.coOwner.ApellidoPaterno}`,
          Nombre: data.coOwner.Nombre,
          ApellidoPaterno: data.coOwner.ApellidoPaterno,
          Telefono: data.coOwner.Telefono,
          Email: data.coOwner.Email,
          Language: data.ContratoId?.Idioma,
          TipoSocioId: data.coOwner.TipoSocio,
        });

      return result;
    },
    formatDate(date) {
      if (!date || date === 0) return "";
      moment.locale(this.iso);
      return moment(date).format("MMM/DD/YYYY").toUpperCase();
    },
    getBeneficiariesStatic(data) {
      let result = [];
      if (data?.beneficiaries)
        result = [
          data.beneficiaries
            .map(
              (b) =>
                `${this.formatTextBySearch(
                  b.Nombre,
                  "Nombre"
                )} ${this.formatTextBySearch(
                  b.ApellidoPaterno,
                  "ApelidoPaterno"
                )}`
            )
            .join(", "),
        ];

      console.log("result", result);
      return result.filter((b) => b && b !== "");
    },
    async exportAllMembers(filters = {}) {
      try {
        this.loadingExportMembers = true;
        let query = `?export=s`;
        const { data } = await axios.post(
          `${API_URL}/catcher/members/list-all${query}`,
          filters
        );
        console.log("members", data);

        const blob = new Blob([data.members.csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "members.csv");
        a.click();
        this.loadingExportMembers = false;
      } catch (err) {
        this.loadingExportMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al exportar datos",
          type: "is-danger",
        });
      }
    },
    checkValidateAdvancedFilters() {
      if (this.advancedFilters.contractStatus === 1) {
        this.validateAdvancedFilters.FechaCancelacion = true;
        this.validateAdvancedFilters.FechaRenovacion = true;

        this.advancedFilters.FechaCancelacion = [];
        this.advancedFilters.FechaRenovacion = [];
      } else {
        this.validateAdvancedFilters.FechaCancelacion = false;
        this.validateAdvancedFilters.FechaRenovacion = false;
      }
    },
    async listAllUsersStaff() {
      try {
        // this.loadingClubes = true;
        const { data } = await axios.post(`${API_URL}/system/list-staff`);
        this.staff = data?.users || [];
        // this.loadingClubes = false;
      } catch (err) {
        // this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de staff",
          type: "is-danger",
        });
      }
    },
    async listAllUsers() {
      try {
        // this.loadingClubes = true;
        const { data } = await axios.post(
          `${API_URL}/system/list-users?limit=1000`
        );
        this.allUsers = data?.users || [];
        // this.loadingClubes = false;
      } catch (err) {
        // this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de usuarios",
          type: "is-danger",
        });
      }
    },
    checkContract(a, b) {
      // console.log("row", this.checkedRows);
      return true;
    },
    async assignStaffToContractsFetch() {
      if (this.checkedRows.length > 0) {
        try {
          this.assignStaffToContractsFetch__loading = true;
          await axios.post(
            `${API_URL}/catcher/assign-staff/contract?type=renewal`,
            {
              contractIds: this.checkedRows.map((c) => c.Id),
              staff: this.selectedStaff,
              updatedBy: localStorage.getItem("user"),
            }
          );
          this.$buefy.toast.open({
            message: this.i18n.massiveAssignContracts__msg1[this.iso],
            type: "is-success",
          });
          this.checkedRows = [];
          this.selectedStaff = [];
          this.advancedSearch();
          this.assignStaffToContractsFetch__loading = false;
        } catch (err) {
          this.assignStaffToContractsFetch__loading = false;
          console.log(err);
          this.$buefy.toast.open({
            message: this.i18n.massiveAssignContracts__msg3[this.iso],
            type: "is-danger",
          });
        }
      }
    },
    async assignStaffToContracts() {
      if (this.checkedRows.length > 0 && this.selectedStaff.length > 0) {
        // const staff = this.staff.find((s) => s.Usuario === this.selectedStaff);
        this.$buefy.dialog.confirm({
          message: this.i18n.massiveAssignContracts__msg4[this.iso]
            .replace("{{total}}", this.checkedRows.length)
            .replace(
              "{{totalStaff}}",
              this.selectedStaff.length
              // staff ? `${staff?.Nombre || ""} ${staff?.Apellido || ""}` : ""
            ),
          cancelText: this.i18n.cancel[this.iso],
          confirmText: this.i18n.accept[this.iso],
          type: "is-success",
          onConfirm: async () => await this.assignStaffToContractsFetch(),
        });
      }
    },
    checkSubscription(contract) {
      const now = moment().endOf("day").valueOf();
      if (
        contract &&
        contract?.FechaRenovacion &&
        contract?.FechaRenovacion < now
      )
        return false;
      else return true;
    },
    checkMembership(contract) {
      if (contract && contract?.EstadoMembresia === true) return 1;
      else if (contract && contract?.EstadoMembresia === false) return 2;
      else return 3;
    },
    viewStaff(user) {
      if (!user) return null;
      else {
        const staff = this.allUsers.find((s) => s.Usuario === user);
        if (!staff) return "";
        else return `${staff?.Nombre || ""} ${staff?.Apellido || ""}`;
      }
    },
    showDateAssignmentRenewal(date) {
      if (!date) return null;
      else {
        return moment(date).locale(this.iso).format("LL, h:mm:ss");
      }
    },
    async removeAssignments() {
      if (this.checkedRows.length > 0) {
        this.$buefy.dialog.confirm({
          message: this.i18n.removeAssignments__msg1[this.iso].replace(
            "{{total}}",
            this.checkedRows.length
          ),
          cancelText: this.i18n.cancel[this.iso],
          confirmText: this.i18n.accept[this.iso],
          type: "is-danger",
          onConfirm: async () => await this.removeAssignmentsFetch(),
        });
      }
    },
    async removeAssignmentsFetch() {
      if (this.checkedRows.length > 0) {
        try {
          this.removeAssignmentsFetch__loading = true;
          await axios.post(
            `${API_URL}/catcher/assign-staff/contract?type=renewal-remove`,
            {
              contractIds: this.checkedRows.map((c) => c.Id),
              updatedBy: localStorage.getItem("user"),
            }
          );
          this.$buefy.toast.open({
            message: this.i18n.removeAssignments__msg2[this.iso],
            type: "is-success",
          });
          this.checkedRows = [];
          this.selectedStaff = null;
          this.advancedSearch(false);
          this.removeAssignmentsFetch__loading = false;
        } catch (err) {
          this.removeAssignmentsFetch__loading = false;
          console.log(err);
          this.$buefy.toast.open({
            message: this.i18n.removeAssignments__msg3[this.iso],
            type: "is-danger",
          });
        }
      }
    },
    async createPaymentsLinks() {
      try {
        this.createPaymentsLinks__loading = true;

        await axios.post(`${API_URL}/renewals/create-portfolio`, {
          ...this.paymentLink,
          UserAgente: localStorage.getItem("user"),
          EmailAgente: localStorage.getItem("userEmail"),
          ContratoIds: this.checkedRows.map((c) => c.Id),
        });

        this.paymentLink.Years = 1;
        this.paymentLink.PrincipalPagador = "T";
        this.paymentLink.Monto = 199;

        // this.renewalUrl = response.data.url;
        this.$buefy.toast.open({
          message: this.i18n.createPaymentsLinks__msgOk[this.iso],
          type: "is-success",
        });

        this.createPaymentsLinks__loading = false;
        this.checkAvailableToSend();
        this.paymentLink.Years = 1;
        this.paymentLink.PrincipalPagador = "T";
        this.paymentLink.Monto = 199;
        this.checkedRows = [];
        await this.advancedSearch(false);
      } catch (err) {
        console.log(err);
        this.createPaymentsLinks__loading = false;
        this.$buefy.toast.open({
          message: "Error al crear enlace de pago",
          type: "is-danger",
        });
      }
    },
    ifCharged(contract) {
      if (
        contract &&
        contract?.FechaRenovacion &&
        contract?.FechaRenovacionPortfolio &&
        contract?.FechaRenovacion !== contract?.FechaRenovacionPortfolio
      )
        return true;
      else return false;
    },
    findClubName(clubId) {
      if (clubId) {
        const club = this.clubesName.find((c) => c.Id === clubId);
        if (club) return club?.Nombre || "";
        else return null;
      }
      return null;
    },
    openModalPaymentLinks(payLinks) {
      this.payLinksSelected = payLinks;
      this.ModalPaymentLinkRenewal = true;
    },
    closeModalPaymentLinks() {
      this.ModalPaymentLinkRenewal = false;
      this.payLinksSelected = [];
      this.isShowOptionsSendPayments = null;
    },
    async copyPaymentLink(id) {
      try {
        navigator.clipboard.writeText(
          `${process.env.VUE_APP_SHORT_URL_PAYMENTLINK}/${id}`
        );
        this.$buefy.toast.open({
          message: this.i18n.linkCopy__msg1[this.iso],
          type: "is-info",
        });
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          message: this.i18n.linkCopy__msg3[this.iso],
          type: "is-danger",
        });
      }
    },
    async reSendPaymentLink(Id, sendTo) {
      try {
        this.loadingResendPaymentLink = `${Id}${sendTo}`;
        await axios.get(
          `${API_URL}/renewals/resend-vaucher/${Id}?sendTo=${sendTo}`
        );

        this.$buefy.toast.open({
          message: this.i18n.sendLinkPayment__msg1[this.iso],
          type: "is-success",
        });
        this.loadingResendPaymentLink = null;
        this.closeModalPaymentLinks();
        this.advancedSearch(false);
      } catch (err) {
        this.loadingResendPaymentLink = null;
        console.log(err);
        this.$buefy.toast.open({
          message: this.i18n.sendLinkPayment__msg2[this.iso],
          type: "is-danger",
        });
      }
    },
    checkEnvios(payLinks, id) {
      if (id === 199691) {
        console.log(payLinks && Array.isArray(payLinks) && payLinks.length > 0);
      }
      if (payLinks && Array.isArray(payLinks) && payLinks.length > 0) {
        return payLinks.reduce((acum, value) => {
          if (id === 199691) {
            console.log(value, acum + (value?.Envios || 0));
          }
          return acum + (value?.Envios || 0);
        }, 0);
      } else return 0;
    },
    checkChange(items) {
      if (items.length > 1) {
        this.paymentLink.Years = 1;
        this.paymentLink.Monto = 199;
        this.disabledYears = true;
        this.disabledMonto = true;
      } else {
        this.disabledYears = false;
        this.disabledMonto = false;
        if (items.length === 1) {
          console.log("items", items);
          this.paymentLink.Monto =
            this.paymentLink.Years * (items[0]?.Tarifa || 199);
        } else {
          this.paymentLink.Years = 1;
          this.paymentLink.Monto = 199;
        }
      }
    },
    checkPrice(items) {
      let price = this.PRICE_BASE_CONTRACT;
      if (this.checkedRows.length === 1) {
        const contract = this.checkedRows[0];
        price = contract?.Tarifa || 199;
      }

      this.paymentLink.Monto =
        this.paymentLink.Years === 99
          ? price * 10
          : price * this.paymentLink.Years;
    },
    showMsgPrincipalPagador() {
      if (this.paymentLink.PrincipalPagador === "T") {
        return this.i18n.createPaymentsLinks__msg6[this.iso];
      }
      if (this.paymentLink.PrincipalPagador === "C") {
        return this.i18n.createPaymentsLinks__msg7[this.iso];
      }
      if (this.paymentLink.PrincipalPagador === "B") {
        return this.i18n.createPaymentsLinks__msg8[this.iso];
      }
      if (this.paymentLink.PrincipalPagador === "N") {
        return this.i18n.createPaymentsLinks__msg9[this.iso];
      }
    },
    parseDate(value) {
      moment.locale(this.iso);
      return value ? moment(value).utc().format("MMMM D, YYYY") : "";
    },
    async statsAdmin() {
      try {
        if (this.statsFilters.FechaAsignacion.length === 0) {
          this.$buefy.dialog.alert({
            title: "Error",
            message: this.i18n.statsAdmin__msg1[this.iso],
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        } else {
          this.statsAdmin__loading = true;
          const { data } = await axios.post(
            `${API_URL}/renewals/stats/portfolio-admin`,
            {
              ...this.statsFilters,
            }
          );

          this.allStats = [];
          if (data?.stats && Object.keys(data?.stats).length > 0) {
            if (data?.stats && data?.stats?.general)
              this.allStats.push({
                name: "General",
                data: data?.stats?.general,
              });

            for (let i in data?.stats) {
              if (i !== "general") {
                this.allStats.push({
                  name: i,
                  data: data?.stats[i],
                });
              }
            }
          } else {
            this.$buefy.dialog.alert({
              title: this.i18n.noData[this.iso],
              message: this.i18n.noData__msg1[this.iso],
              type: "is-warning",
              hasIcon: true,
              icon: "exclamation-triangle",
              iconPack: "fas",
              ariaRole: "alertdialog",
              ariaModal: true,
            });
          }

          this.statsAdmin__loading = false;
        }
      } catch (err) {
        console.log(err);
        this.statsAdmin__loading = false;
      }
    },
    checkTabResults(tab) {
      // console.log("tab", tab);
      if (tab === 1) this.tabFilters = 0;
    },
    findAgentName(username) {
      if (username) {
        const agent = this.staff.find((c) => c.Usuario === username);
        if (agent) return `${agent?.Nombre || ""} ${agent?.Apellido || ""}`;
        else return username;
      }
      return username;
    },
    exportDataToCsv() {
      this.exportDataToCsv__loading = true;
      const items = this.members.map((b) => {
        const club = this.findClubName(b?.ClubId);
        const agent = this.findAgentName(b?.AgenteRenovacion);
        const cobrado = this.ifCharged(b);

        return {
          Contrato: b?.Numero || "",
          LoginID: b?.LoginID || "",
          Titular: b?.titular || "",
          Club: club || "",
          FechaRenovacion: moment(b.FechaRenovacion)
            .locale("es")
            .format("DD-MM-YYYY"),
          FechaCompra: moment(b.FechaRenovacion)
            .locale("es")
            .format("DD-MM-YYYY"),
          Idioma: b?.Idioma || "",
          Agente: agent || "",
          Estado: cobrado ? "COBRADO" : "NO COBRADO",
        };
      });
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]);
      const csv = [
        header.join(","),
        ...items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");

      // console.log(csv);

      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", "results_renewals.csv");
      a.click();
      this.exportDataToCsv__loading = false;
    },
    inputFilterRenewal(date) {
      const date1 = moment(date).startOf("month").valueOf();
      const date2 = moment(date).endOf("month").valueOf();
      this.advancedFilters.FechaRenovacion = [date1, date2];
      // console.log("date", date);
    },
    inputFilterRenewalRemove() {
      this.FechaRenovacionMes = null;
      this.advancedFilters.FechaRenovacion = [];
    },
    inputFilterAssignedRenewal(date) {
      const date1 = moment(date).startOf("month").valueOf();
      const date2 = moment(date).endOf("month").valueOf();
      this.advancedFilters.FechaAsignacionRenovacion = [date1, date2];
      // console.log("date", date);
    },
    inputFilterAssignedRenewalRemove() {
      this.FechaAsignacionRenovacionMes = null;
      this.advancedFilters.FechaAsignacionRenovacion = [];
    },
    showOptionsSendPayments(Id) {
      if (this.isShowOptionsSendPayments !== Id)
        this.isShowOptionsSendPayments = Id;
      else this.isShowOptionsSendPayments = null;

      // this.activeShowOptionsConfirmation = null;
      // this.isShowOptionsConfirmation = false;

      // if (this.activeShowOptionsPayments === numberRef)
      //   this.activeShowOptionsPayments = null;
      // else this.activeShowOptionsPayments = numberRef;
      // this.isShowOptionsPayments = !this.isShowOptionsPayments;
    },
    selectedAllClubsInFilters() {
      this.advancedFilters.ClubId = this.clubesRgx.map((c) => c.Id);
      this.checkSelectedAllClubsInFilters = true;
    },
    removeSelectedAllClubsInFilters() {
      this.advancedFilters.ClubId = [];
      this.checkSelectedAllClubsInFilters = false;
    },
    changeSelectedAllClubsInFilters(e, i) {
      if (e.length === 0) this.checkSelectedAllClubsInFilters = false;
      else this.checkSelectedAllClubsInFilters = true;
      // console.log('e', e);
    },
    findSelectedAllClubsInFilters(e) {
      const rgx = new RegExp(e, "ig");
      console.log("e", e);
      if (e.trim() === "") this.clubesRgx = [...this.clubesName];
      else this.clubesRgx = this.clubesName.filter((c) => rgx.test(c.Nombre));
      // console.log('this.clubesRgx', this.clubesRgx);
    },
  },
};
</script>
